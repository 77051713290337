<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    @getData="importAttendanceData(null, $event)"
    :hasPreConsultationValue="hasAttendanceValues('preConsultation')"
  >
    <div v-if="hasHtml() && !canEdit" v-html="form.fields.historicoDaDoencaAtual" class="form-group mb-0 break-spaces"></div>
    <div v-else class="form-group mb-0 break-spaces">
      <TextArea
        id="historia-da-doenca-atual"
        :value="form.fields.historicoDaDoencaAtual"
        @blur="onBlur(form)"
        @inputTextArea="el => updateForm('historicoDaDoencaAtual', el)"
        :readonly="!this.validCanEdit()"
        rows="1"
      />
    </div>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    TextArea: () => import('@/components/General/TextArea'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      canDefaultForms: state => state.attendance.canDefaultForms,
      form: state => state.attendance.form.historiaDaDoencaAtual,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore,
      selectedForm: state => state.attendance.form.selected,
    }),
    ...mapGetters('attendance', ['hasPreConsultation', 'preConsultation'])
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  methods: {
    ...mapActions('attendance', ['editDefaultForms']),
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord', 'defaultUpdateForm']),
    ...mapActions('attendance/form/historiaDaDoencaAtual', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
      this.updateMedicalRecord(this.form)
    },
    onBlur(value) {
      this.updateMedicalRecord(value)
      if(this.canDefaultForms) {
        this.defaultUpdateForm(value)
        this.$toast.success('Campo história da doença atual salvo com sucesso')
      }
    },
    hasHtml() {
      return /<\/?[a-z][\s\S]*>/i.test(this.form.fields.historicoDaDoencaAtual);
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    },
    async importAttendanceData(medicalRecords = null, type) {
      const data = medicalRecords || this[type].medical_records
      const attendanceData = data.find(el => { return el.type === 'historia-da-doenca-atual'})

      if (!attendanceData?.id) return
      Object.keys(attendanceData.value).map(key => {
        if (attendanceData.value[key]) 
          this.updateForm(`${key}`, attendanceData.value[key])
      })
      await this.updateMedicalRecord(this.form)
    },
    validCanEdit() {
      if(this.canDefaultForms === true || this.canEdit === true){
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    selectedForm() {
      const form = this.selectedForm.forms.find(f => f.id === 'historia-da-doenca-atual');

      if (form && form.default_text && this.canEdit) {
        // Atualizando de forma reativa
        setTimeout(() => {
          this.updateForm('historicoDaDoencaAtual', form.default_text)
					}, "3000")

      }
    }
  }
}
</script>
